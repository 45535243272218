// category denotes a page (example: products)
// section denotes a heading (example: inventory)

const roles = {
  // general
  General_SectionRoles_Section: ['admin'],

  // inventory
  Inventory_SectionRoles_Section: ['admin', 'inventory'],
  Inventory_Changes_Section: ['admin', 'product_approval_admin'],

  // lab-receiving
  LabReceiving_SectionRoles_Section: ['admin', 'supervisor', 'warehouse'],
  LabReceiving_Containers_Roles: ['admin', 'supervisor'],
  LabReceiving_Flashpoint_Classes_Roles: ['admin', 'supervisor'],

  // production
  Production_SectionRoles_Main: ['admin'],
  Production_PageRoles_Dashboard: [
    'admin',
    'planning',
    'supervisor',
    'requester',
    'batcher',
    'bottler',
    'labeler',
    'transfer',
    'shipping',
    'recovery',
    'stamper'
  ],
  Production_PageRoles_Transfer: [
    'admin',
    'manager',
    'qa_manager',
    'hplc_tech',
    'supervisor_batcher',
    'supervisor_production',
    'transfer',
    'shipping',
    'planning',
    'batcher',
    'bottler',
    'labeler',
    'stamper'
  ],
  transferQRCodeGenerationRoles: ['admin', 'supervisor_production', 'supervisor', 'transfer', 'shipping', 'requester'],
  Production_PageRoles_ProductionOrder: [
    'admin',
    'batcher',
    'bottler',
    'labeler',
    'transfer',
    'shipping',
    'supervisor_batcher',
    'supervisor_production',
    'hplc_tech',
    'manager',
    'planning',
    'qa_manager',
    'stamper'
  ],
  Production_PageRoles_DailyOrder: [
    'admin',
    'requester',
    'supervisor_batcher',
    'supervisor_production',
    'hplc_tech',
    'manager',
    'planning',
    'qa_manager'
  ],
  productionLabelCheckRoles: [
    'admin',
    'supervisor_production',
    'bottler',
    'labeler',
    'stamper',
    'transfer',
    'shipping'
  ],
  Production_PageRoles_Pending: ['admin', 'manager', 'planning'],
  Production_PageRoles_Pending_Reel: ['admin', 'manager', 'stamp_inventory'],
  Production_PageRoles_Production: ['admin', 'supervisor_batcher', 'supervisor_production', 'manager'],
  Production_PageRoles_Hplc: ['admin', 'qa_manager', 'hplc_tech'],
  Production_PageRoles_Product_Stamping_Orders: ['admin', 'stamper'],
  Production_PageRoles_Admin: ['admin'],
  Production_SectionRoles_Recipes: [
    'admin',
    'batcher',
    'supervisor_batcher',
    'hplc_tech',
    'manager',
    'recipes',
    'qa_manager',
    'recipe_approval'
  ],
  Production_PageRoles_Imports_Recipes: ['admin'],
  Production_SectionRoles_Report: [
    'admin',
    'supervisor_batcher',
    'supervisor_production',
    'hplc_tech',
    'manager',
    'planning',
    'qa_manager'
  ],

  // assembly
  Assembly_Section_Roles_Main: ['admin'],
  Assembly_Page_Roles_Assembly_Orders: ['admin'],
  Assembly_Dashboard_Roles: ['admin', 'sealer', 'stamper', 'cartoner', 'transfer'],
  Assembly_Shipping_Dashboard_Roles: ['admin', 'shipping'],

  // projects
  Projects_SectionRoles_Section: ['admin', 'director', 'manager'],

  // purchase
  Purchase_SectionRoles_Section: ['admin', 'purchase'],

  // rd
  RD_PageRoles_Projects: ['admin', 'rd_requester', 'rd_supervisor'],
  RD_PageRoles_Sample: ['admin', 'rd_flavorist', 'rd_supervisor', 'rd_quality_control'],
  RD_PageRoles_RD: [
    'admin',
    'recipes',
    'batcher',
    'supervisor_batcher',
    'hplc_tech',
    'manager',
    'qa_manager',
    'head_batcher',
    'rd_requester',
    'rd_flavorist',
    'rd_supervisor',
    'rd_quality_control'
  ],
  RD_PageRoles_Reports: ['admin', 'recipes', 'hplc_tech', 'manager', 'qa_manager'],

  // sale
  Sale_SectionRoles_Section: ['admin', 'director', 'manager'],

  // training
  Training_PageRoles_Trainer: ['admin', 'manager', 'qa_manager', 'training_supervisor', 'trainer'],
  Training_PageRoles_Training: ['admin', 'manager', 'qa_manager', 'training_supervisor'],
  Training_PageRoles_Change: ['admin', 'qa_manager', 'training_supervisor'],
  Training_PageRoles_Admin: ['admin', 'training_supervisor'],

  // PO Reports
  poReportRoles: [
    'admin',
    'supervisor_batcher',
    'supervisor_production',
    'hplc_tech',
    'manager',
    'planning',
    'qa_manager'
  ],
  poReportExportRoles: ['manager', 'admin', 'planning', 'qa_manager', 'supervisor_batcher', 'supervisor_production'],

  // PRODUCTION ORDER
  poDashboardRoles: [
    'admin',
    'planning',
    'supervisor',
    'requester',
    'batcher',
    'bottler',
    'labeler',
    'transfer',
    'shipping',
    'recovery'
  ],
  poDashboardChangeRoles: ['admin', 'batcher', 'bottler', 'labeler'],
  productionOrderRoles: [
    'admin',
    'batcher',
    'bottler',
    'labeler',
    'transfer',
    'shipping',
    'supervisor_batcher',
    'supervisor_production',
    'hplc_tech',
    'manager',
    'planning',
    'qa_manager'
  ],
  poAdminRoles: ['admin', 'planning'],
  productStampingOrderCorrectionsRoles: ['admin', 'supervisor', 'manager', 'stamp_inventory'],
  prodOrdersCorrectionsRoles: [
    'admin',
    'supervisor',
    'manager',
    'supervisor_batcher',
    'supervisor_production',
    'stamp_inventory'
  ],
  pendingRoles: ['admin', 'manager', 'planning'],
  poTransferRoles: [
    'admin',
    'manager',
    'qa_manager',
    'hplc_tech',
    'supervisor_batcher',
    'supervisor_production',
    'transfer',
    'shipping',
    'planning',
    'batcher',
    'bottler',
    'labeler'
  ],

  // REELS
  reelsPagesRoles: ['admin', 'stamp_inventory', 'supervisor_production'],
  reelsCreateRoles: ['admin', 'stamp_inventory'],
  reelsLimitedEditRoles: ['admin', 'stamp_inventory'],

  // DAILY ORDERS
  dailyOrderRoles: [
    'admin',
    'requester',
    'supervisor_batcher',
    'supervisor_production',
    'hplc_tech',
    'manager',
    'planning',
    'qa_manager'
  ],
  // RD
  rdRoles: [
    'admin',
    'recipes',
    'batcher',
    'supervisor_batcher',
    'hplc_tech',
    'manager',
    'qa_manager',
    'head_batcher',
    'rd_requester',
    'rd_flavorist',
    'rd_supervisor',
    'rd_quality_control'
  ],
  rdNewRoles: ['admin', 'rd_flavorist', 'rd_requester', 'rd_supervisor'],
  rdProjectRoles: ['admin', 'rd_requester', 'rd_supervisor'],
  rdSampleRoles: ['admin', 'rd_flavorist', 'rd_supervisor', 'rd_quality_control'],
  rdRecipes: [
    'admin',
    'batcher',
    'rd_flavorist',
    'supervisor_batcher',
    'hplc_tech',
    'manager',
    'recipes',
    'qa_manager',
    'head_batcher'
  ],
  rdRecipeChange: ['admin', 'recipes', 'head_batcher'],
  // -
  receivingRoles: ['admin', 'warehouse'],
  projectRoles: ['admin', 'director', 'manager'],
  purchaseRoles: ['admin', 'purchase'],
  saleRoles: ['admin', 'sale'],
  // TRAINING
  trainingRoles: ['admin', 'manager', 'qa_manager'],
  trainingChangeRoles: ['admin', 'qa_manager', 'training_supervisor'],

  // Forecast
  Forecast_SectionRoles_Section: ['admin', 'manager'],

  // Warehouse Product Locations
  warehouseProductsLocationsRoles: ['admin']
}

export default roles
