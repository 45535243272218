/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
// Layout without Header
import { AppMain } from '@/layout/components'
// Roles for Permissions
import roles from './roles'

const labReceivingRouter = {
  path: '/lab-receiving',
  component: Layout,
  redirect: 'noRedirect',
  name: 'LabReceivingModule',
  alwaysShow: true,
  meta: {
    module: 'LabReceiving',
    title: 'Lab Receiving',
    icon: 'warehouses',
    roles: [
      ...roles.LabReceiving_SectionRoles_Section,
      ...roles.LabReceiving_Containers_Roles,
      ...roles.LabReceiving_Flashpoint_Classes_Roles
    ]
  },
  children: [
    {
      path: '/lab-receiving-main',
      component: AppMain,
      name: 'lab-receiving-main',
      alwaysShow: true,
      meta: {
        title: 'MAIN',
        breadcrumb: false
      },
      children: [
        {
          name: 'LabReceiving',
          path: 'receiving',
          component: () => import('@/views/lab-receiving/receiving'),
          meta: { title: 'Receiving', icon: 'receiving' }
        },
        {
          name: 'LabReceivingContainers',
          path: 'receiving-containers',
          component: () => import('@/views/lab-receiving/receiving-containers'),
          meta: { title: 'Receiving Containers', icon: 'containers', roles: roles.LabReceiving_Containers_Roles }
        },
        {
          name: 'LabReceivingContainerForm',
          path: 'receiving-container/:id?',
          component: () => import('@/views/lab-receiving/receiving-container-form'),
          meta: { title: 'Receiving Container Form', icon: 'containers', roles: roles.LabReceiving_Containers_Roles },
          hidden: true
        },
        {
          name: 'QrCodeGeneration',
          path: 'qr-code-generation',
          component: () => import('@/views/lab-receiving/qr-code-form'),
          meta: { title: 'Qr Code Generation', icon: 'qr-code' }
        },
        {
          name: 'FlashpointClasses',
          path: 'flashpoint-classes',
          component: () => import('@/views/lab-receiving/flashpoint-classes'),
          meta: { title: 'Flashpoint Classes', icon: 'fire', roles: roles.LabReceiving_Flashpoint_Classes_Roles }
        }
      ]
    },
    {
      path: '/lab-receiving-reports',
      component: AppMain,
      name: 'lab-receiving-reports',
      alwaysShow: true,
      meta: {
        title: 'REPORTS',
        breadcrumb: false
      },
      children: [
        {
          name: 'LabReceivingItemsList',
          path: 'receiving-items-list',
          component: () => import('@/views/lab-receiving/reports/receiving-items-list'),
          meta: {
            title: 'Receiving Items List',
            icon: 'file-spreadsheet',
            noCache: true
          }
        }
      ]
    }
  ]
}

export default labReceivingRouter
